<template>
  <div class="card card-custom">
    <div class="card-header card-header-tabs-line">
      <div class="card-title">
        <h3 class="card-label">{{ $t('REISETERMINE.RELEASEPROZESS.VORGANGTITLE') }}</h3>
      </div>
      <div class="card-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line">
          <li class="nav-item">
            <router-link
              :to="{
                path: '',
                query: { releasepruefungstab: 'teilnehmer' },
              }"
              v-slot="{ href }"
            >
              <a class="nav-link" :class="{ active: activeTabFromRouter === 'teilnehmer' }" :href="href">
                <span class="nav-icon"><i class="fas fa-user-check"></i></span>
                <span class="nav-text">{{ $t('REISETERMINE.RELEASEPROZESS.TEILNEHMERDATEN') }}</span>
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{
                path: '',
                query: { releasepruefungstab: 'reiseleistungen' },
              }"
              v-slot="{ href }"
              ><a
                class="nav-link"
                :class="{ active: activeTabFromRouter === 'reiseleistungen' }"
                :href="href"
              >
                <span class="nav-icon"><i class="far fa-handshake"></i></span>
                <span class="nav-text">Gebuchte Reiseleistungen & Listenversand</span>
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              :to="{
                path: '',
                query: { releasepruefungstab: 'fluege' },
              }"
              v-slot="{ href }"
              ><a class="nav-link" :class="{ active: activeTabFromRouter === 'fluege' }" :href="href">
                <span class="nav-icon"><i class="fas fa-plane"></i></span>
                <span class="nav-text">Flüge</span>
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!isInitialLoading" class="tab-content">
        <div
          v-show="activeTabFromRouter === 'teilnehmer'"
          :class="{ show: activeTabFromRouter === 'teilnehmer' }"
          class="tab-pane active"
        >
          <div v-if="reiseterminObj">
            <ReleaseprocessKonfigurationBar
              :konfiguration="gueltigeKonfiguration"
            ></ReleaseprocessKonfigurationBar>
          </div>
          <ReiseterminCardVorgaengeReleaseProcessTeilnehmer
            v-if="vorgaenge"
            :isLoading="isLoading"
            :reiseterminStartdatum="reiseterminObj.startdatum"
            :reiseterminEnddatum="reiseterminEnddatum"
            :reiseterminObj="reiseterminObj"
            :konfiguration="gueltigeKonfiguration"
            :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung"
            :passdatenCheckRequired="passdatenCheckRequired"
            :dokumenteCheckRequired="dokumenteCheckRequired"
            :vorgaenge="filteredVorgaenge"
            @release-process-step-updated="saveReleaseprozessProcess"
            @workflow-updated="emitReleaseprocessUpdated"
          >
          </ReiseterminCardVorgaengeReleaseProcessTeilnehmer>
        </div>
        <div
          v-show="activeTabFromRouter === 'reiseleistungen'"
          :class="{ show: activeTabFromRouter === 'reiseleistungen' }"
          class="tab-pane active"
        >
          <ReiseterminCardVorgaengeReleaseProcessReiseleitungen
            v-if="vorgaenge"
            :isLoading="isLoading"
            :vorgaenge="filteredVorgaenge"
            :passdatenCheckRequired="passdatenCheckRequired"
            :reiseterminObj="reiseterminObj"
            :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung"
            :dokumenteCheckRequired="dokumenteCheckRequired"
            @release-process-step-updated="saveReleaseprozessProcess"
            @release-process-step-updated-multi="saveReleaseprozessProcessMulti"
            @workflow-updated="emitReleaseprocessUpdated"
          >
          </ReiseterminCardVorgaengeReleaseProcessReiseleitungen>
        </div>
        <div
          v-show="activeTabFromRouter === 'fluege'"
          :class="{ show: activeTabFromRouter === 'fluege' }"
          class="tab-pane active"
        >
          <ReiseterminCardVorgaengeReleaseProcessFluege
            v-if="vorgaenge"
            :isLoading="isLoading"
            :reiseterminObj="reiseterminObj"
            :passdatenCheckRequired="passdatenCheckRequired"
            :dokumenteCheckRequired="dokumenteCheckRequired"
            :isInTransferplanung="isInTransferplanung"
            :isReiseterminInReleasePruefung="isReiseterminInReleasePruefung"
            :vorgaenge="filteredVorgaenge"
            @release-process-step-updated="saveReleaseprozessProcess"
            @workflow-updated="emitReleaseprocessUpdated"
            @fluege-change-accepted="acceptFluegeChanged($event)"
            @transferplanung-updated="saveTransferplanungProcess"
          >
          </ReiseterminCardVorgaengeReleaseProcessFluege>
        </div>
      </div>
      <div v-else class="tab-content">
        <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER,
  SAVE_VORANG_RELEASEPROCESS_STEP1,
  SAVE_VORANG_RELEASEPROCESS_STEP1_ARRAY,
  SAVE_TRANSFERPLANUNG_PROCESS,
  RESET_TRANSFERPLANUNG_FLUEGE,
} from '@/core/vorgaenge/stores/vorgaenge.module';
import { GET_RELEASEPROCESS_TRIGGERS } from '@/core/produkte/reisetermine/stores/reisetermin-triggers.module';
import ReiseterminCardVorgaengeReleaseProcessTeilnehmer from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-release-process-teilnehmer.vue';
import ReiseterminCardVorgaengeReleaseProcessReiseleitungen from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-release-process-reiseleitungen.vue';
import ReiseterminCardVorgaengeReleaseProcessFluege from '@/components/produkte/reisetermine/reisetermin-card-vorgaenge-release-process-fluege.vue';
import ReleaseprocessKonfigurationBar from '@/components/vorgaenge/releaseprocess-konfiguration-bar.vue';

const moment = require('moment');

export default {
  name: 'ReiseterminViewCardVorgaengeReleaseProcess',
  components: {
    ReiseterminCardVorgaengeReleaseProcessTeilnehmer,
    ReiseterminCardVorgaengeReleaseProcessReiseleitungen,
    ReiseterminCardVorgaengeReleaseProcessFluege,
    ReleaseprocessKonfigurationBar,
  },
  props: {
    reiseterminObj: { type: Object },
    isReiseterminInReleasePruefung: { type: Boolean },
    isInTransferplanung: { type: Boolean, default: false },
    defaultTab: { type: Number },
    gueltigeKonfiguration: { type: Object },
  },
  data() {
    return {};
  },
  async mounted() {
    if (Array.isArray(this.vorgaenge) && this.vorgaenge.length <= 0 && !this.isLoading) {
      this.getTeilnehmerAndFluege(this.reiseterminObj.id);
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.vorgaenge.isLoading,
      isInitialLoading: state => state.vorgaenge.isInitialLoading,
      vorgaenge: state => state.vorgaenge.vorgaenge,
    }),
    filteredVorgaenge() {
      return this.vorgaenge.filter(
        vorgang =>
          vorgang.blankState === 'Buchung' || vorgang.blankState === 'StornierteBuchung' || vorgang.isReleased
      );
    },
    activeTabFromRouter() {
      return this.$route.query.releasepruefungstab || 'teilnehmer';
    },
    reiseterminEnddatum() {
      const reisedauer = this.reiseterminObj?.metadata?.reisedauer
        ? this.reiseterminObj.metadata.reisedauer - 1
        : 0;
      return moment(this.reiseterminObj.abreisedatum).add(reisedauer, 'days');
    },
    dokumenteCheckRequired() {
      return this.gueltigeKonfiguration?.dokumente;
    },
    passdatenCheckRequired() {
      return this.gueltigeKonfiguration?.passdaten;
    },
  },
  watch: {
    async reiseterminObj() {
      this.getTeilnehmerAndFluege(this.reiseterminObj.id);
    },
  },
  methods: {
    emitReleaseprocessUpdated() {
      this.$emit('releaseprocess-updated');
    },
    async saveTransferplanungProcess(vorgangId, transferplanungObject) {
      await this.$store
        .dispatch(SAVE_TRANSFERPLANUNG_PROCESS, {
          id: vorgangId,
          transferplanungObject: transferplanungObject,
        })
        .then(() => {
          this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
        });
    },
    async saveReleaseprozessProcess(vorgangId, releaseProcessObject) {
      await this.$store
        .dispatch(SAVE_VORANG_RELEASEPROCESS_STEP1, {
          id: vorgangId,
          releaseProcessObject: releaseProcessObject,
        })
        .then(() => {
          this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          const { reiseterminId } = this.$route.params;
          this.$store.dispatch(GET_RELEASEPROCESS_TRIGGERS, reiseterminId);
        });
    },
    async saveReleaseprozessProcessMulti(releaseProcessObjectsArray) {
      await this.$store
        .dispatch(SAVE_VORANG_RELEASEPROCESS_STEP1_ARRAY, {
          reiseterminId: this.reiseterminObj.id,
          releaseProcessObjectsArray: releaseProcessObjectsArray,
        })
        .then(() => {
          this.toast(this.$t('WORKFLOWS.COMMON.SAVED'));
          const { reiseterminId } = this.$route.params;
          this.$store.dispatch(GET_RELEASEPROCESS_TRIGGERS, reiseterminId);
        });
    },
    async getTeilnehmerAndFluege(reiseterminId) {
      return this.$store.dispatch(GET_VORGAENGE_WITH_FLIGHTS_TEILNEHMER, {
        reiseterminId: reiseterminId,
      });
    },
    async acceptFluegeChanged($event) {
      await this.$store.dispatch(RESET_TRANSFERPLANUNG_FLUEGE, { id: $event.flugData.vorgangId }).then(() => {
        this.getTeilnehmerAndFluege(this.reiseterminObj.id);
      });
    },
  },
};
</script>
